import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Table, TableRow, TableHead, makeStyles, styled, useTheme, TableBody, Box } from '@material-ui/core'
import FilterListIcon from '@material-ui/icons/FilterList'
import CloseIcon from '@material-ui/icons/Close'
import { usStatesToCodes } from '../data/countryNames'
import { formatMoney } from '../util/money'
import { actSetSelectedProxy, actSetFilter } from '../redux/backend/proxies'
import ProxyFilter from './ProxyFilter'
// import { useAuth0 } from '@auth0/auth0-react'
import { useAuth0 } from "../redux/auth"
import QualityScore from './QualityScore'
import { useTableStyles, TableDataCell, TableHeadCell, mediumColumnWidth, smallColumnWidth, LoadingRow } from './table'
import NoResults from './NoResults'
import SmartTooltip from './SmartTooltip'
import TableContainer from '@mui/material/TableContainer'

const useStyles = makeStyles((theme) => ({
    filterIcon: {
        position: 'relative',
        top: 3,
        opacity: 0.7,
        cursor: 'pointer',
        '&:hover, &:focus': {
            color: theme.palette.primary.main,
        },
    },
    proxyNew: {
        color: '#24B35F'
    },
    usedIp: {
    	color: 'red'
    },
    newIp: {
    	color: '#09920E'
    }
}))

export const connTypeLabels = {
    'wifi': "Wifi",
    'cell': "Cell"
}

const ProxyTable = (props) => {

    const classes = useTableStyles()
    const extraClasses = useStyles()
    const theme = useTheme()

    const [filterOn, setFilterOn] = useState(true)

    const HeadCell = TableHeadCell(theme)
    const DataCell = TableDataCell(theme)

    const PriceCell = styled(DataCell)({
        fontWeight: 600,
        color: theme.palette.common.black,
    })

    const page = props.pagination.page
    const rowsPerPage = props.pagination.rowsPerPage

    const handleRowClick = (proxy) => {
        props.dispatch(actSetSelectedProxy(proxy))
    }

    const {getAccessTokenSilently} = useAuth0()

    const handleFilterIconClick = () => {
        if (filterOn) {
            props.dispatch(actSetFilter(getAccessTokenSilently, {}, true))
        }
        setFilterOn(!filterOn)
    }

    const size = useWindowSize()

    function useWindowSize() {

	  const [windowSize, setWindowSize] = useState({
	    width: undefined,
	    height: undefined,
	  });

	  useEffect(() => {

		  function handleResize() {
           setWindowSize({
        	   width: window.innerWidth,
        	   height: window.innerHeight,
           });
		  }

		  window.addEventListener("resize", handleResize);

		  handleResize();
		  return () => window.removeEventListener("resize", handleResize);
	  }, []);

	  return windowSize;
    }

    var ispCellSize = (size.width <= 620)?120:'44%';
    var regionCellSize = (size.width <= 620)?120:'23%';
    var cityCellSize = (size.width <= 620)?120:'33%';

    let body = null
    if (!props.loading && props.proxies?.length) {
        body = (props.proxies || []).map((proxy) => {//.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((proxy) => {
            let isp = ""
            if (proxy.loc?.mob) {
                isp = proxy.loc?.mob
                if (proxy.loc?.isp) {
                    isp = isp + " (" + proxy.loc?.isp + ")"
                }
            } else if (proxy.loc?.isp) {
                isp = proxy.loc?.isp
            }

            const regCode = proxy.loc ? (props.country === 'US' ? usStatesToCodes[proxy.loc.reg] : proxy.loc.reg) : ""
            const price = proxy.priceShrC ? formatMoney(proxy.priceShrC) : formatMoney(proxy.priceExcC)
            const connType = connTypeLabels[proxy.conn]

            let leaseTypeStatus = "Private"

            if (proxy.stars <= 5) {
              if (proxy.leases != null && proxy.leases.count > 0) {
                  leaseTypeStatus = proxy.leases.count + " of 4" // TODO config from backend
              }
            }else {
              if (proxy.leases != null && proxy.leases.count > 0) {
                  leaseTypeStatus = proxy.leases.count + " of 4" // TODO config from backend
              }
            }

            let isNew = 'Yes'
            if (proxy.leases?.worn) {
                isNew = 'No'
            }

            let isUsed = false
            if (proxy?.used) {
            	isUsed = true
            }

            return (
                <TableRow
                    hover
                    key={proxy.id}
                    className={classes.dataRow}
                    classes={{ selected: classes.selected }}
                    selected={props.selected?.id === proxy.id}
                    onClick={() => handleRowClick(proxy)}
                >
                    <DataCell><Box className={isUsed === true ? extraClasses.usedIp : ''}>{proxy.ip}</Box></DataCell>
                    <DataCell><SmartTooltip>{regCode}</SmartTooltip></DataCell>
                    <DataCell><SmartTooltip>{proxy.loc ? proxy.loc.city : ""}</SmartTooltip></DataCell>
                    <DataCell>{proxy.loc ? proxy.loc.zip : ""}</DataCell>
                    <DataCell><SmartTooltip>{isp}</SmartTooltip></DataCell>
                    <DataCell align='center'><QualityScore score={proxy.stars} /></DataCell>
                    <DataCell>{leaseTypeStatus}</DataCell>
                    <DataCell>{connType}</DataCell>
                    <DataCell><Box className={isNew === 'Yes' ? extraClasses.proxyNew : ''}>{isNew}</Box></DataCell>
                    <PriceCell align='right'><Box className={isUsed == true ? extraClasses.usedIp : proxy.stars <= 5 ? extraClasses.newIp : ''}>{price}</Box></PriceCell>
                    <DataCell></DataCell>
                </TableRow>
            )
        })
    } else if (props.loading) {
        body = <LoadingRow />
    }

    return (
        <Box className={classes.tableAndNoResultsContainer}>
            <TableContainer sx={{ maxHeight: 550 }}>
            <Table stickyHeader className={classes.table}>
                <TableHead>
                    <TableRow>
                        <HeadCell style={{width: mediumColumnWidth}}>IP</HeadCell>
                        <HeadCell style={{width: regionCellSize}}>Region</HeadCell>
                        <HeadCell style={{width: cityCellSize}}>City</HeadCell>
                        <HeadCell style={{width: smallColumnWidth}}>ZIP</HeadCell>
                        <HeadCell style={{width: ispCellSize}}>ISP</HeadCell>
                        <HeadCell align='center' style={{width: smallColumnWidth}}>Clean</HeadCell>
                        <HeadCell style={{width: smallColumnWidth}}>Shared</HeadCell>
                        <HeadCell style={{width: smallColumnWidth}}>Conn</HeadCell>
                        <HeadCell style={{width: smallColumnWidth}}>New</HeadCell>
                        <HeadCell style={{width: smallColumnWidth}} align='right'>Price</HeadCell>
                        <HeadCell align='left' style={{width: 60, paddingRight: theme.spacing(2)}}>
                            {!filterOn && <FilterListIcon style={{left: 3}} className={extraClasses.filterIcon} onClick={handleFilterIconClick} />}
                            {filterOn && <CloseIcon className={extraClasses.filterIcon} onClick={handleFilterIconClick} />}
                        </HeadCell>
                    </TableRow>
                    {filterOn && <ProxyFilter />}
                </TableHead>
                <TableBody>
                    {body}
                </TableBody>
            </Table>
            </TableContainer>
            {(!props.loading && !props.proxies?.length) && <NoResults />}
        </Box>
    )
}

const mapStateToProps = (state) => ({
    proxies: state.proxies.proxies,
    pagination: state.proxies.pagination,
    country: state.proxies.filter.country,
    selected: state.proxies.selected,
    loading: state.proxies.loading,
})

export default connect(mapStateToProps)(ProxyTable)
